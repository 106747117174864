import CorrectAnswer from "@assets/wordpuzzle/correct-answer.svg";
import WrontAnswer from "@assets/wordpuzzle/wrong-answer.svg";
import { useEffect, useState } from "react";

type StatusMessageProps = {
  status: string;
  isExpanded: boolean;
  incorrectCombination: string;
};

export default function StatusMessage({
  status,
  isExpanded,
  incorrectCombination,
}: StatusMessageProps) {
  const [progress, setProgress] = useState(0);
  const duration = 3000;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (status === "correct" && prev < 100) {
          return prev + 1;
        } else if (prev >= 100) {
          return 0;
        } else {
          return 100;
        }
      });
    }, duration / 100);

    return () => {
      clearInterval(interval);
    };
  }, [status]);

  return (
    <>
      {!isExpanded && status === "correct" && (
        <div className="grid  items-center shadow-lg justify-center flex-row w-full h-14 bg-green-500">
          <div className="w-10 bg-green-700 h-0.5 rounded-300 mx-auto">
            <div
              className="h-full bg-green-600 transition-[width] duration-100 ease-in-out"
              style={{
                width: `${progress}%`,
              }}
            />
          </div>
          <div className="flex">
            <img src={CorrectAnswer} className="w-7 h-7" alt="Status Correct" />
            <span className="pl-2 font-primary text-2sm font-bold text-green-600 leading-6">
              சரியான விடை!
            </span>
          </div>
        </div>
      )}
      {!isExpanded && status === "incorrect" && (
        <div className="flex items-center shadow-lg justify-center flex-row w-full h-14 bg-red-600 px-4">
          <img src={WrontAnswer} className="w-7 h-7" alt="Status Incorrect" />
          <span className="pl-2 text-2sm font-primary font-bold text-red-700 leading-6">
            தவறு! {incorrectCombination}
          </span>
        </div>
      )}
    </>
  );
}
