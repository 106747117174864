import { capitalizeFirstCharOnly } from "@helper/stringTransform";
import { isArrayWithElements, isClientSide } from "@helper/utils";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import CollapseSection from "./CollapseSection";
import { shuffle } from "./helpers/shuffle";
import { usePuzzleData } from "./hooks/usePuzzleData";
import QuestionDetailSection from "./QuestionDetailSection";
import StatusMessage from "./StatusMessage";

// type HandleQuizNavigation = (direction: number) => void;
export type QuizExpandEvent = {
  quizExpanded: boolean;
  isMobile: boolean;
};

export type WordPuzzleProps = {
  DefaultQuestions?: { [key: string]: string }[];
  isCatfishEnabled: boolean | undefined;
}; // Props with DefaultQuestions from storiesbook

export default function WordPuzzle(props: WordPuzzleProps) {
  const { DefaultQuestions, isCatfishEnabled } = props;
  const currentDate = new Date();
  const cookieExpand = Cookies.get("puzzle_popup_collapsed") === "1";
  const [options, setOptions] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [isExpanded, setIsExpanded] = useState<boolean>(cookieExpand);
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [englishWord, setEnglishWord] = useState<string>(""); // The question
  const [correctTamilWord, setCorrectTamilWord] = useState<string>("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const [incorrectCombination, setIncorrectCombination] = useState<string>("");
  const [questionNum, setQuestionNum] = useState<number>(1);
  const puzzleDate = `${currentDate.getDate().toString().padStart(2, "0")}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getFullYear()}`;
  const [showNextPre, setShowNextPrev] = useState<boolean>(false);
  const { questions } = usePuzzleData(puzzleDate);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [hasAnswered, setHasAnswered] = useState<boolean>(false); // Added state to track if the question has been answered
  const [checkVisibleDataLayer, setCheckVisibleDataLayer] =
    useState<boolean>(false);
  const catfishDisplayedCookie = Cookies.get("catfishDisplayed");
  const [isCatfishAvailable, setIsCatfishAvailable] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const tmCustomEventAnalyticsCall = useTMCustomEventAnalyticsCall();

  function handleWindowSizeChange() {
    if (isClientSide) {
      setIsMobile(window.innerWidth <= 768);
    }
  }

  useEffect(() => {
    if (!isClientSide) return;
    setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const pushVisibleEvent = useCallback(() => {
    if (!checkVisibleDataLayer) {
      setCheckVisibleDataLayer(true);
      tmCustomEventAnalyticsCall({
        event: "customEvent",
        eventCategory: "word quiz",
        eventAction: "visible",
        eventLabel: window.location.href,
      });
    }
  }, [tmCustomEventAnalyticsCall, checkVisibleDataLayer]);

  useEffect(() => {
    if (!isClientSide) return;
    const handleVisibilityCheck = () => {
      const element = document.getElementById("word-puzzle");
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          pushVisibleEvent();
        }
      }
    };

    const checkReadyState = () => {
      if (document.readyState === "complete") {
        handleVisibilityCheck();
      } else {
        const timeoutId = setTimeout(checkReadyState, 100);
        return timeoutId;
      }
    };

    // Initial call to check if document is ready
    const timeoutId = checkReadyState();

    // Clean up the timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [pushVisibleEvent]);

  useEffect(() => {
    if (isArrayWithElements(DefaultQuestions) || questions.length) {
      loadQuestion(
        isArrayWithElements(DefaultQuestions)
          ? DefaultQuestions[0]
          : questions[0],
      );
    }
  }, [questions, DefaultQuestions]);

  const removeCookieAtMidnight = useCallback(() => {
    const currentDate = new Date();
    const tomorrow = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
      0,
      1,
      0,
    );
    const timeUntilMidnight = tomorrow.getTime() - currentDate.getTime();
    setTimeout(() => {
      Cookies.set("puzzle_popup_collapsed", "1");
      removeCookieAtMidnight();
    }, timeUntilMidnight);
  }, []);

  useEffect(() => {
    removeCookieAtMidnight();
  }, [removeCookieAtMidnight]);

  const loadQuestion = (question: { [key: string]: string }) => {
    const tamilWords = Object.keys(question);
    const questionWord = tamilWords[0];
    setEnglishWord(questionWord);
    const correctAnswer = question[questionWord];
    setCorrectTamilWord(correctAnswer);
    setOptions(shuffle([...tamilWords.slice(1), correctAnswer]));
    setHasAnswered(false); // Reset hasAnswered when loading a new question
    setSelectedWord(null);
  };

  // Handle on scroll behaviour.
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsExpanded(true);
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleWordClick = (e: React.SyntheticEvent, word: string) => {
    e.stopPropagation();
    setSelectedWord(word);

    if (word === correctTamilWord) {
      if (!resultsVisible || showNextPre) {
        tmCustomEventAnalyticsCall({
          event: "customEvent",
          eventCategory: "word quiz",
          eventAction: "success",
          eventLabel: window.location.href,
        });
        setStatus("correct");
        setIncorrectCombination(""); // Clear incorrect combination on correct answer
      }
      setTimeout(() => {
        if (!resultsVisible || showNextPre) setStatus("correctNext");
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex < questions.length) {
          setQuestionNum(questionNum + 1);
          setCurrentQuestionIndex(nextIndex);
          loadQuestion(questions[nextIndex]);
          setSelectedWord(null);
        } else {
          setShowNextPrev(true);
          setResultsVisible(true);
          setStatus("");
          setTimeout(() => {
            setIsExpanded(false);
            // Emit the custom event
            const event = new CustomEvent<QuizExpandEvent>(
              "quizExpandedChange",
              {
                detail: { quizExpanded: false, isMobile: isMobile },
              },
            );
            window.dispatchEvent(event);
            Cookies.set("puzzle_popup_collapsed", "1");
          }, 3000);
        }
      }, 3000);
    } else {
      tmCustomEventAnalyticsCall({
        event: "customEvent",
        eventCategory: "word quiz",
        eventAction: "failed",
        eventLabel: window.location.href,
      });
      setStatus("incorrect");
      setIncorrectCombination(
        `${capitalizeFirstCharOnly(word)} = ${capitalizeFirstCharOnly(questions[currentQuestionIndex][word])}`,
      );
      if (!hasAnswered) {
        // Check if the question has already been answered incorrectly
        setHasAnswered(true); // Mark the question as answered
      }
      setTimeout(() => {}, 1000);
    }
  };

  const toggleExpand = () => {
    if (isExpanded && !isScrolled) {
      tmCustomEventAnalyticsCall({
        event: "customEvent",
        eventCategory: "word quiz",
        eventAction: "click -",
        eventLabel: window.location.href,
      });
    }
    setIsScrolled(false);
    setIsExpanded((prev) => !prev);
    // Emit the custom event
    const event = new CustomEvent<QuizExpandEvent>("quizExpandedChange", {
      detail: { quizExpanded: !isExpanded, isMobile: isMobile },
    });
    window.dispatchEvent(event);
    Cookies.set("puzzle_popup_collapsed", isExpanded ? "1" : "0");
  };

  useEffect(() => {
    if (typeof window !== "undefined" && isCatfishEnabled != undefined) {
      if (
        (typeof catfishDisplayedCookie === "undefined" ||
          (window.dfp_preview_ids != undefined &&
            Array.isArray(window.dfp_preview_ids) &&
            window.dfp_preview_ids.length != 0)) &&
        isMobile &&
        isCatfishEnabled
      ) {
        setIsCatfishAvailable(true);
      }
    }
  }, [catfishDisplayedCookie, isCatfishEnabled, isMobile]);

  return (
    <>
      {questions.length > 0 && (
        <div
          id="word-puzzle"
          className={twMerge(
            "fixed z-50 w-full left-0",
            isCatfishAvailable ? "bottom-[100px]" : "bottom-0",
          )}
        >
          <div>
            <StatusMessage
              status={status}
              isExpanded={isExpanded}
              incorrectCombination={incorrectCombination}
            />
            <CollapseSection
              isMobile={isMobile}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
            />
            <QuestionDetailSection
              isMobile={isMobile}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
              questionNum={questionNum}
              questions={
                isArrayWithElements(DefaultQuestions) &&
                DefaultQuestions.length > 0
                  ? DefaultQuestions
                  : questions
              }
              englishWord={englishWord}
              options={options}
              selectedWord={selectedWord}
              correctTamilWord={correctTamilWord}
              handleWordClick={handleWordClick}
              showResult={resultsVisible}
            />
          </div>
        </div>
      )}
    </>
  );
}
