import Quiz from "@components/Quiz/Quiz";
import MainContainer from "@container/MainContainer";
import WrapperBlock from "@container/WrapperBlock";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import QuizMetaData from "./QuizMetaData";

export default function QuizPage() {
  const sectionName = "tamil-quiz";
  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
  const { gaData, metaTags } = QuizMetaData(sectionName);
  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="justify-between">
        <Quiz />
      </WrapperBlock>
    </MainContainer>
  );
}
