import "./exclusive.css";

import Android_Logo from "@assets/mobile_app/android_logo.svg";
import Apple_Logo from "@assets/mobile_app/apple_logo.svg";
import TM_Picks_Phone_Graphicdesktop from "@assets/mobile_app/TM_Picks_Phone_Graphic.png";
import TM_Picks_Phone_Graphicmobile from "@assets/mobile_app/TM_Picks_Phone_Graphic_mobile.png";
import MainBlock from "@container/MainBlock";
import Divider from "@elements/Divider/Divider";
import { Image } from "@elements/ResponsiveImage/Image";
import { ReactElement } from "react";

type AppExclusiveContentProps = {
  title: string;
  sectionName?: string;
};

export default function AppExclusiveContent({
  title,
  sectionName,
}: AppExclusiveContentProps): ReactElement {
  return (
    <>
      <MainBlock
        showBreadcrumbs={true}
        sectionName={sectionName}
        pageName={title}
      >
        <div className="my-3">
          <div className="native-banner-mobile-app flex">
            <div className="native-banner-main-image">
              <Image
                objectFit="contain"
                src={TM_Picks_Phone_Graphicdesktop}
                className="desktop-graphic"
                srcWidth={611}
                srcHeight={380}
                alt="tm-picks-phone-graphics"
                dataTestId="image"
              />
              <Image
                objectFit="contain"
                src={TM_Picks_Phone_Graphicmobile}
                className="mobile-graphic"
                srcWidth={644}
                srcHeight={325}
                alt="tm-picks-phone-graphics"
                dataTestId="image"
              />
            </div>

            <div className="native-banner-main-desc relative">
              <div className="desc-main-text text-white-100 text-xs">
                <p className="tmpick-headline">App exclusive content!</p>
                <p className="leading-6">
                  விளக்கப்படச் செய்திகளுக்குத் தமிழ் முரசு செயலியைப் பதிவிறக்கம்
                  செய்யுங்கள்
                </p>
              </div>

              <div className="mobile-app-links-wrapper flex mt-3 gap-2">
                <div className="mobile-app-ios bg-white-100 text-center">
                  <a
                    className="tm-app-ios-link"
                    href="https://apps.apple.com/app/apple-store/id6447955016?pt=123719093&amp;ct=TM_Website_Banner&amp;mt=8"
                  >
                    <img alt="" className="inline-block" src={Apple_Logo} />
                    <span className="inline-block pl-1 align-bottom">
                      செயலி இணைப்பு
                    </span>
                  </a>
                </div>

                <div className="mobile-app-android bg-white-100 text-center">
                  <a
                    className="tm-app-android-link"
                    href="https://play.google.com/store/apps/details?id=com.sph.tmandroid&pcampaignid=web_share"
                  >
                    <img alt="" className="inline-block" src={Android_Logo} />
                    <span className="inline-block pl-1">பதிவிறக்கம் செய்ய</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider className="mt-2 border-b border-gray-2200" />
      </MainBlock>
    </>
  );
}
