import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { COMSCORE_PUBLISHER_ID } from "@helper/getEnvVariables";
import { useCallback } from "react";

export type TmCustomEventArticleProps = {
  event: string;
  eventLabel?: string;
  eventCategory?: string;
  eventAction?: string;
  articleid?: string;
  contenttype?: string;
  chapter1?: string;
  level2?: string;
  pubdate?: string;
  title?: string;
  url?: string;
  keyword?: string;
  byline?: string;
  cue_articleid?: string;
  landingPage?: string;
  nextNodeTitle?: string;
  nextNodeUrl?: string;
  abVariant?: string;
  pagination?: number;
};

export function useTMCustomEventAnalyticsCall(): (
  data: TmCustomEventArticleProps,
) => void {
  const sendDataToGTM = useGTMDispatch();

  // Destructuring the properties from the argument
  const analyticsCall = useCallback(
    (data: TmCustomEventArticleProps) => {
      sendDataToGTM({
        event: data.event,
        ...(data.eventLabel && { eventLabel: data.eventLabel }),
        ...(data.eventCategory && { eventCategory: data.eventCategory }),
        ...(data.eventAction && { eventAction: data.eventAction }),
        ...(data.articleid && { articleid: data.articleid }),
        ...(data.contenttype && { contenttype: data.contenttype }),
        ...(data.chapter1 && { chapter1: data.chapter1 }),
        ...(data.level2 && { level2: data.level2 }),
        ...(data.pubdate && { pubdate: data.pubdate }),
        ...(data.title && { title: data.title }),
        ...(data.url && { url: data.url }),
        ...(data.keyword && { keyword: data.keyword }),
        ...(data.byline && { byline: data.byline }),
        ...(data.cue_articleid && { cue_articleid: data.cue_articleid }),
        ...(data.landingPage && { landingPage: data.landingPage }),
        ...(data.nextNodeTitle && { nextNodeTitle: data.nextNodeTitle }),
        ...(data.nextNodeUrl && { nextNodeUrl: data.nextNodeUrl }),
        ...(data.abVariant && { abVariant: data.abVariant }),
        ...(data.pagination && { pagination: data.pagination }),
      });
    },
    [sendDataToGTM],
  );

  return analyticsCall;
}

export function useComscoreCall(): () => void {
  const comscoreCall = useCallback(() => {
    window.COMSCORE &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      window.COMSCORE.beacon({
        c1: "2",
        c2: COMSCORE_PUBLISHER_ID,
        options: {
          enableFirstPartyCookie: true,
        },
      });
  }, []);

  return comscoreCall;
}
