import { capitalizeFirstCharOnly } from "@helper/stringTransform";
import { isArrayWithElements, isClientSide } from "@helper/utils";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { shuffle } from "./helpers/shuffle";
import QuestionDetailSection, { Questions } from "./QuestionDetailSection";
import { fetchPuzzleData } from "./server/fetchPuzzleData";
import StatusMessage from "./StatusMessage";

type HandleQuizNavigation = () => void;

export type QuizProps = {
  DefaultQuestions?: Questions;
}; // Props with DefaultQuestions from storiesbook

export default function Quiz(props: QuizProps): JSX.Element {
  const { DefaultQuestions } = props;
  const currentDate = new Date();
  const puzzleDate = `${currentDate.getDate().toString().padStart(2, "0")}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getFullYear()}`;
  const [options, setOptions] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [englishWord, setEnglishWord] = useState<string>(""); // The question
  const [correctTamilWord, setCorrectTamilWord] = useState<string>("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const [incorrectCombination, setIncorrectCombination] = useState<string>("");
  const [questionNum, setQuestionNum] = useState<number>(1);
  const [showNextPre, setShowNextPrev] = useState<boolean>(false);
  const [puzzles, setPuzzles] = useState<Questions>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [hasAnswered, setHasAnswered] = useState<boolean>(false); // Added state to track if the question has been answered

  const tmCustomEventAnalyticsCall = useTMCustomEventAnalyticsCall();

  function handleWindowSizeChange() {
    if (isClientSide) {
      setIsMobile(window.innerWidth <= 768);
    }
  }

  useEffect(() => {
    if (!isClientSide) return;
    setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const pushQuizTMCustomEvent = useCallback(
    (
      eventAction: "visible" | "success" | "failed" | "click",
      pagination?: number,
    ) => {
      tmCustomEventAnalyticsCall({
        event: "customEvent",
        eventCategory: "word quiz",
        eventAction: eventAction,
        eventLabel: window.location.href,
        pagination: pagination,
      });
    },
    [tmCustomEventAnalyticsCall],
  );

  useEffect(() => {
    async function load_initial_puzzle() {
      const data = (await fetchPuzzleData(puzzleDate)) as Questions;
      if (isArrayWithElements(DefaultQuestions) || data.length) {
        loadQuestion(
          isArrayWithElements(DefaultQuestions) ? DefaultQuestions[0] : data[0],
        );
        if (data.length) {
          pushQuizTMCustomEvent("visible", 1);
        }
      }
      setPuzzles(data);
    }
    void load_initial_puzzle();
  }, [puzzleDate, DefaultQuestions, pushQuizTMCustomEvent]);

  const loadQuestion = (question: { [key: string]: string }) => {
    const tamilWords = Object.keys(question);
    const questionWord = tamilWords[0];
    setEnglishWord(questionWord);
    const correctAnswer = question[questionWord];
    setCorrectTamilWord(correctAnswer);
    setOptions(shuffle([...tamilWords.slice(1), correctAnswer]));
    setHasAnswered(false); // Reset hasAnswered when loading a new question
    setSelectedWord(null);
  };

  const handleWordClick = useCallback(
    (word: string) => {
      setSelectedWord(word);

      if (word === correctTamilWord) {
        if (!resultsVisible || showNextPre) {
          pushQuizTMCustomEvent("success", questionNum);
          setStatus("correct");
          setIncorrectCombination(""); // Clear incorrect combination on correct answer
        }
        setTimeout(() => {
          if (!resultsVisible || showNextPre) setStatus("correctNext");
          const nextIndex = currentQuestionIndex + 1;
          if (nextIndex < puzzles.length) {
            setQuestionNum(questionNum + 1);
            setCurrentQuestionIndex(nextIndex);
            loadQuestion(puzzles[nextIndex]);
            setSelectedWord(null);
          } else {
            setShowNextPrev(true);
            setResultsVisible(true);
            pushQuizTMCustomEvent("visible");
            setStatus("");
          }
        }, 3000);
      } else {
        pushQuizTMCustomEvent("failed", currentQuestionIndex + 1);
        setStatus("incorrect");
        setIncorrectCombination(
          `${capitalizeFirstCharOnly(word)} = ${capitalizeFirstCharOnly(puzzles[currentQuestionIndex][word])}`,
        );
        if (!hasAnswered) {
          // Check if the question has already been answered incorrectly
          setHasAnswered(true); // Mark the question as answered
        }
        setTimeout(() => {}, 1000);
      }
    },
    [
      puzzles,
      correctTamilWord,
      currentQuestionIndex,
      hasAnswered,
      questionNum,
      resultsVisible,
      showNextPre,
      pushQuizTMCustomEvent,
    ],
  );

  const handleQuizNavigation: HandleQuizNavigation = () => {
    pushQuizTMCustomEvent("click");
    setOptions([]);
    setStatus("");
    setCorrectTamilWord("");
    setQuestionNum(1);
    setCurrentQuestionIndex(0);
    setResultsVisible(false);
    fetchPuzzleData(puzzleDate)
      .then((data) => {
        setPuzzles(data as Questions);
        if (data) {
          loadQuestion(data[0]);
          pushQuizTMCustomEvent("visible", 1);
        }
      })
      .catch(() => {
        setResultsVisible(true);
      });
  };

  return (
    <div id="word-puzzle-page" className={twMerge("z-50 w-full mb-24")}>
      <div>
        {!isMobile && (
          <StatusMessage
            status={status}
            incorrectCombination={incorrectCombination}
            resultsVisible={resultsVisible}
          />
        )}
        <QuestionDetailSection
          isMobile={isMobile}
          questionNum={questionNum}
          questions={
            isArrayWithElements(DefaultQuestions) && DefaultQuestions.length > 0
              ? DefaultQuestions
              : puzzles
          }
          englishWord={englishWord}
          options={options}
          selectedWord={selectedWord}
          correctTamilWord={correctTamilWord}
          handleWordClick={handleWordClick}
          resultVisible={resultsVisible}
          handleQuizNavigation={handleQuizNavigation}
          status={status}
          incorrectCombination={incorrectCombination}
        />
      </div>
    </div>
  );
}
