/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CAS_ACTIVATION_URL, CAS_API_KEY } from "@helper/getEnvVariables";
import useCasScript from "@hooks/useCasScript";
import { useEffect } from "react";

export type CASBlockProps = {
  casId: number;
  testId?: string;
  className?: string;
  index?: number;
};

export default function CASBlock({
  casId,
  testId,
  className,
  index,
}: CASBlockProps): React.ReactElement {
  const PREFIX = "sph_cdp";
  const { isScriptReady: isCasScriptReady } = useCasScript();
  const initId = index ? `${casId}_${index}` : `${casId}`;

  useEffect(() => {
    if (isCasScriptReady) {
      if (typeof window === "undefined") return;
      if (typeof window._data === "undefined") return;
      if (typeof window._data.level2 === "undefined") return;
      if (typeof window.SPH_CDP !== "function") return;
      try {
        setTimeout(() => {
          window
            .SPH_CDP()
            .init(initId, CAS_ACTIVATION_URL, CAS_API_KEY, "tm_sg")
            .run();
        }, 2000); // 2 seconds delay
      } catch (e) {
        console.log(e);
      }
    }
  }, [isCasScriptReady, initId]);

  return (
    <div
      id={`${PREFIX}_${initId}`}
      data-testid={testId}
      className={className}
    ></div>
  );
}
