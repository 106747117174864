import fetchTMApi from "@helper/fetchTMApi";

import { Questions } from "../QuestionDetailSection";

interface PuzzleData {
  [key: string]: Questions;
}
export const fetchPuzzleData = async (
  formattedDate: string,
): Promise<Questions | null> => {
  try {
    const response = await fetchTMApi<PuzzleData>(
      "feeds/quiz-processed",
      "GET",
    );
    const result = response[formattedDate];
    return result;
  } catch (error) {
    console.error("Error fetching puzzle data:", error);
    return null;
  }
};
