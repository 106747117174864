import { getWindowOrigin } from "@helper/utils";

async function fetchTMApiBase<T>(
  endpoint: string,
  method: "GET" | "POST",
  requestData?: unknown,
): Promise<T> {
  const url = `${getWindowOrigin()}/_plat/api/v1/${endpoint}`;
  const options: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: method === "POST" ? JSON.stringify(requestData) : undefined,
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`HTTP error on fetch ${url}! Status: ${response.status}`);
  }
  return response.json() as Promise<T>;
}

export default async function fetchTMApi<T>(
  endpoint: string,
  method: "GET" | "POST",
  requestData?: unknown,
): Promise<T> {
  try {
    return await fetchTMApiBase(endpoint, method, requestData);
  } catch (error) {
    console.error("Error occurred during fetch:", error);
    throw error;
  }
}
