import Heading from "@elements/Typography/Heading";
import { Helmet } from "react-helmet-async";

export type LiveblogProps = {
  liveblog_id: string;
  title?: string;
};

export default function Liveblog({
  liveblog_id,
  title = "நேரடித் தகவல்",
}: LiveblogProps) {
  return (
    <>
      <div className="mx-auto flex items-center justify-center mb-5">
        <div className="w-full bg-gradient-to-r from-pink-701 to-pink-301 p-1">
          <div className="w-full min-h-[400px] bg-white-100">
            <Heading
              Element="h2"
              className="text-2sm  text-white-100 font-bold leading-400 pb-2 pl-4 bg-gradient-to-r from-pink-701 to-pink-301 p-1"
            >
              {title}
            </Heading>
            <iframe
              name="lb24"
              id="lb24"
              loading="lazy"
              width="100%"
              className="lb24-iframe h-[432px] lg:h-[1260px] !overflow-visible overflow-y-visible"
              title={title}
              src={"//v.24liveblog.com/iframe/?id=" + liveblog_id}
            ></iframe>
            <Helmet>
              <script src="https://v.24liveblog.com/iframe.js"></script>
            </Helmet>

            {/* <div id="LB24_LIVE_CONTENT" data-eid={liveblog_id}></div>
              <script src="https://v.24liveblog.com/24.js"></script> */}
          </div>
        </div>
      </div>
    </>
  );
}
