import { capitalizeFirstCharOnly } from "@helper/stringTransform";
import { twMerge } from "tailwind-merge";

type QuestionCardProps = {
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctTamilWord: string;
  onWordClick: (word: string) => void;
  isMobile?: boolean;
};

function QuestionCard({
  englishWord,
  options,
  selectedWord,
  correctTamilWord,
  onWordClick,
  isMobile,
}: QuestionCardProps): JSX.Element {
  return (
    <div
      className={
        isMobile
          ? "flex flex-col items-center mt-lg mb-xl gap-4 px-sm"
          : "flex flex-col items-center my-lg gap-4 min-w-[400px] w-[400px]"
      }
    >
      <div className="mb-0">
        <span
          id="staticEnglish_web"
          className="font-secondary font-semibold text-gray-100 text-2md"
        >
          {capitalizeFirstCharOnly(englishWord)} =
        </span>
      </div>
      <div className={`flex w-full flex-col ${isMobile && "items-center"}`}>
        <ul
          className={`inline-flex flex-col items-center gap-4 p-0 list-none ${isMobile && "w-full max-w-[375px]"}`}
        >
          {options.map((word, index) => (
            <div
              key={index}
              onClick={() => {
                onWordClick(word);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onWordClick(word);
                }
              }}
              role="button"
              tabIndex={0}
              className={twMerge(
                "font-primary flex justify-center items-center w-full px-1.5 h-[40px] cursor-pointer text-gray-150 text-2sm lg:text-md leading-6 font-bold rounded-300",
                selectedWord !== null
                  ? word === selectedWord
                    ? word === correctTamilWord
                      ? "bg-green-400 text-white-100"
                      : "bg-red-800 text-white-100"
                    : "border-1 bg-gray-500 lg:bg-white-100"
                  : "bg-gray-500 lg:bg-white-100",
              )}
            >
              {capitalizeFirstCharOnly(word)}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QuestionCard;
