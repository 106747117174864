import IframeResizer from "iframe-resizer-react";
import { useEffect, useState } from "react";

type DataGovEmbedCustomProps = {
  url: string;
};

export default function DataGovEmbedCustom({
  url,
}: DataGovEmbedCustomProps): React.ReactElement {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="justify-center">
      <IframeResizer
        src={url}
        checkOrigin={false}
        heightCalculationMethod={isMobile ? "bodyScroll" : "max"}
        style={{
          width: "1px",
          minWidth: isMobile ? "95%" : "100%",
          border: "none",
          minHeight: isMobile ? "1040px" : "642px",
        }}
      />
    </div>
  );
}
