import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@pages/AboutUs/AboutTmHistory.css";

import captionIcon from "@assets/about-us/caption_icon.svg";
import captionOnclickIcon from "@assets/about-us/caption_onclick_icon.svg";
import logoImg from "@assets/about-us/dot.svg";
import lineImg from "@assets/about-us/Line.svg";
import tmHistoryBackground from "@assets/about-us/TM_History.jpeg";
import enabledArrow from "@assets/actions/enable-carousel-button.svg";
import Image from "@components/ResponsiveImage/Image";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { TM_HISTORY } from "@pages/AboutUs/AboutUsConstants";
import cx from "classnames";
import { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

export default function AboutTmHistory() {
  const {
    isScreenXL,
    isScreenLG,
    isScreenMD,
    isScreenSM,
    isScreenXS,
    isScreenXXS,
  } = useWindowSize();
  let bigSlideCount = 2.5;
  let smallSlideCount = 1;
  if (isScreenXL) {
    bigSlideCount = 2.5;
  } else if (isScreenLG) {
    bigSlideCount = 2.1;
  } else if (isScreenMD) {
    smallSlideCount = 2.3;
  } else if (isScreenSM) {
    smallSlideCount = 2;
  } else if (isScreenXS) {
    smallSlideCount = 1.15;
  } else if (isScreenXXS) {
    smallSlideCount = 1.1;
  }
  // eslint-disable-next-line
  const SliderComponent: typeof Slider =
    // eslint-disable-next-line
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const sliderRef = useRef<Slider>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  // Need to do this as workaroudn known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return <span {...others}>{children}</span>;
  };

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: bigSlideCount,
    className: cx(
      "lg:grid-cols-1 lg:block lg:-mr-5 lg:w-full lg:!m-auto lg:px-5",
    ),
    arrows: true,
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous} data-testid="prev-button">
          <img
            width={20}
            height={20}
            src={enabledArrow}
            alt="prev-arrow"
            className="-scale-x-100 lg:w-9.5 xl:w-10"
          />
        </button>
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next} data-testid="next-button">
          <img
            width={20}
            height={20}
            src={enabledArrow}
            alt="next-arrow"
            className="lg:w-9.5 xl:w-10"
          />
        </button>
      </SlickButtonFix>
    ),
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: smallSlideCount,
          swipeToSlide: true,
          arrows: false,
        },
      },
    ],
  };

  const [isCaptionVisible, setCaptionVisible] = useState<number | null>(null);
  const toggleCaption = (index: number) => {
    setCaptionVisible((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      className="about-history py-10 lg:py-xxl"
      style={{
        backgroundImage: `url(${tmHistoryBackground})`,
      }}
    >
      <div className="text-center mb-6 lg:mb-9">
        <div className="text-orange-500 text-3md lg:text-md leading-650 font-primary font-semibold">
          தமிழ் முரசு வரலாறு
        </div>
        <div className="text-gray-2050 font-primary font-medium text-3.5md leading-9">
          Tamil Murasu History
        </div>
      </div>
      <div className="xl:max-w-xl mx-auto">
        <SliderComponent {...settings}>
          {TM_HISTORY.map((item, index) => {
            return (
              <div key={index}>
                <div className="w-[284px] lg:w-83 pr-2.5 lg:pr-0 font-primary">
                  <div className="relative">
                    <Image
                      srcWidth={388}
                      srcHeight={310}
                      src={item.image}
                      alt={"tm-history"}
                      className="w-[284px] h-[227px] lg:w-full object-cover"
                    />
                    {item.tm_caption && item.en_caption && (
                      <div className="absolute bottom-0 w-full">
                        {isCaptionVisible === index && (
                          <div className="absolute bottom-0 w-full bg-black-100 bg-opacity-70 text-white-100 p-2 pr-20 shadow-9xl">
                            <div className="mb-2 text-3xs lg:text-xs font-normal font-primary leading-180 lg:leading-230">
                              {item.tm_caption}
                            </div>
                            <div className="text-xxs lg:text-1sm font-normal font-primary leading-5 lg:leading-230">
                              {item.en_caption}
                            </div>
                          </div>
                        )}
                        <button
                          onClick={() => {
                            toggleCaption(index);
                          }}
                          className="absolute right-1 bottom-1 focus:outline-none"
                        >
                          {isCaptionVisible === index ? (
                            <Image
                              srcWidth={25}
                              srcHeight={25}
                              src={captionOnclickIcon}
                              alt={"caption"}
                            />
                          ) : (
                            <Image
                              srcWidth={25}
                              srcHeight={25}
                              src={captionIcon}
                              alt={"caption"}
                            />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="h-[274px] lg:h-72 p-2 gap-2 lg:p-4 bg-pink-500 text-white-100">
                    <div className="pb-2 text-2sm lg:text-md font-bold lg:font-semibold leading-6 lg:leading-250">
                      {item.tm_desc}
                    </div>
                    <div className="text-3sm lg:text-1md font-medium leading-410 lg:leading-7">
                      {item.en_desc}
                    </div>
                  </div>

                  <div className="text-gray-2050 text-xl lg:text-6xl font-semibold leading-9 lg:leading-1000 my-2.5">
                    {item.date}
                  </div>
                </div>
                <Image
                  src={index === TM_HISTORY.length - 1 ? logoImg : lineImg}
                  className={cx(
                    "aspect-unset w-[101%] max-w-[101%]", // To resolve the iOS issue, we added 1% more width.
                    index === TM_HISTORY.length - 1 &&
                      "!w-5 h-5 lg:!w-8 lg:h-8.4",
                  )}
                  alt={"line"}
                  srcWidth={0}
                  srcHeight={0}
                />
              </div>
            );
          })}
        </SliderComponent>
      </div>
    </div>
  );
}
