import DesktopMainImg from "@assets/wordpuzzle/Desktop_Main-img_01.svg";
import ExpandIcon from "@assets/wordpuzzle/expand.svg";
import SmilingFace from "@assets/wordpuzzle/smiling-face-with-sunglasses.svg";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import { twMerge } from "tailwind-merge";

import QuestionCard from "./QuestionCard";
import QuizButton from "./QuizButton";

type ResultsProps = {
  isMobile: boolean;
  isExpanded: boolean;
  toggleExpand: () => void;
  questionNum: number;
  questions: { [key: string]: string }[];
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctTamilWord: string;
  handleWordClick: (
    e: React.MouseEvent | React.KeyboardEvent,
    word: string,
  ) => void;
  showResult: boolean;
};

export default function QuestionDetailSection({
  isMobile,
  isExpanded,
  toggleExpand,
  questionNum,
  questions,
  englishWord,
  options,
  selectedWord,
  correctTamilWord,
  handleWordClick,
  showResult,
}: ResultsProps) {
  const tmCustomEventAnalyticsCall = useTMCustomEventAnalyticsCall();
  const handleQuizClose = () => {
    tmCustomEventAnalyticsCall({
      event: "customEvent",
      eventCategory: "word quiz",
      eventAction: "close quiz",
      eventLabel: window.location.href,
    });
  };
  return (
    <>
      {!isMobile ? (
        <button
          onClick={toggleExpand}
          className={twMerge(
            "cursor-pointer",
            isExpanded && "absolute ",
            !isExpanded && "w-full z-10",
          )}
        >
          <div
            className={twMerge(
              "relative flex items-center justify-center bg-gray-500 h-[198px] ease-in-out duration-700 opacity-100 transition-all translate-y-3",
              isExpanded && "h-0 opacity-0 z-0 invisible",
              !isExpanded && "z-50 visible bottom-3",
            )}
            style={{ boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)" }}
          >
            <span className="relative w-[222px]">
              <img
                src={DesktopMainImg}
                className="absolute bottom-[-100px] left-[12px] w-[222px] h-[210px] z-10"
                alt="Woman Icon"
              />
            </span>
            <div className="relative w-[325px] h-[75px] -left-6 pt-2.5">
              <span className="font-primary font-semibold text-xl leading-7 text-pink-900 mb-4">
                முரசு சொல்லாட்டம்
              </span>
              <span className="justify-center relative flex">
                <span className="block text-black-100 font-primary text-xs font-semimedium h-[17px]">
                  உங்கள் தமிழ் மொழித்திறனைச் சோதிக்கவும்
                </span>
              </span>
            </div>
            {!showResult && questions.length > 0 ? (
              <QuestionCard
                englishWord={englishWord}
                options={options}
                selectedWord={selectedWord}
                correctTamilWord={correctTamilWord}
                onWordClick={handleWordClick}
                questionNum={questionNum}
              />
            ) : (
              <div className="flex pt-6 pl-[77px] min-w-[420px] lg:min-w-[350px]">
                <img
                  src={SmilingFace}
                  alt="Smiling Face Icon"
                  className={twMerge("w-2lg h-2lg")}
                />
                <div className="text-center relative -top-5 pl-9">
                  <span className="font-primary font-bold text-2.5md leading-9">
                    நல்ல முயற்சி!
                  </span>
                  <QuizButton
                    handleClick={handleQuizClose}
                    text="அடுத்த புதிர்"
                    buttonStyle="bg-pink-500 text-white-100 font-bold leading-6 text-2sm py-2.5 px-5 rounded-3xl"
                    buttonUrl="/tamil-quiz"
                  />
                </div>
              </div>
            )}
            <span className="w-[36px] h-full text-center pt-3.5 ml-6">
              <img
                src={ExpandIcon}
                alt="Collapse Icon"
                className={twMerge(
                  "cursor-pointer duration-500",
                  !isExpanded && "rotate-180",
                )}
              />
            </span>
          </div>
        </button>
      ) : (
        <button
          onClick={toggleExpand}
          className={twMerge(
            isExpanded && "absolute ",
            !isExpanded && "w-full z-10",
          )}
        >
          <div
            className={twMerge(
              "bg-gray-500 ease-in-out duration-700 opacity-100 transition-all translate-y-3 bottom-2.5 relative",
              isExpanded && "h-0 opacity-0 z-0 invisible",
              !isExpanded && "z-50 visible",
            )}
            style={{ boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)" }}
          >
            <div className={twMerge("block px-4 pt-1", !showResult && "pb-4")}>
              <div
                className={twMerge(
                  "flex h-8.5",
                  !showResult && "border-b border-solid border-gray-1100",
                  showResult && "justify-center",
                )}
              >
                {!showResult && (
                  <img
                    src={DesktopMainImg}
                    alt="logo"
                    className="w-[55px] h-[52px] -top-5 relative"
                  />
                )}
                <span
                  className={twMerge(
                    "relative left-5 block text-pink-900 font-primary text-md font-semibold leading-7",
                    showResult && "text-lg left-0",
                  )}
                >
                  முரசு சொல்லாட்டம்
                </span>
                <span
                  className={twMerge(
                    "h-7 w-7 absolute right-2.5 flex items-center",
                    showResult && "mt-3",
                  )}
                >
                  <img
                    src={ExpandIcon}
                    className={twMerge(
                      "cursor-pointer duration-500",
                      !isExpanded && "rotate-180",
                    )}
                    alt="Collapse Icon"
                  />
                </span>
              </div>
              <div className={twMerge(!showResult && "mt-sm mb-sm")}>
                {!showResult && questions.length > 0 ? (
                  <QuestionCard
                    englishWord={englishWord}
                    options={options}
                    selectedWord={selectedWord}
                    correctTamilWord={correctTamilWord}
                    onWordClick={handleWordClick}
                    isMobile={isMobile}
                    questionNum={questionNum}
                  />
                ) : (
                  <div className="flex justify-center">
                    <img
                      src={DesktopMainImg}
                      alt="Woman Icon"
                      className={twMerge("w-[173px] h-[164px] z-10")}
                    />
                    <div className="text-center relative mt-5">
                      <img
                        src={SmilingFace}
                        alt="Smiling Face Icon"
                        className={twMerge("w-2lg h-2lg mx-auto")}
                      />
                      <span className="font-primary font-bold text-2.5md leading-9">
                        நல்ல முயற்சி!
                      </span>
                      <QuizButton
                        handleClick={handleQuizClose}
                        text="அடுத்த புதிர்"
                        buttonStyle="bg-pink-500 text-white-100 font-bold leading-6 text-2sm py-2.5 px-5 rounded-3xl"
                        buttonUrl="/tamil-quiz"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </button>
      )}
    </>
  );
}
