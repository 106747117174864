import { capitalizeFirstCharOnly } from "@helper/stringTransform";
import { twMerge } from "tailwind-merge";

type QuestionCardProps = {
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctTamilWord: string;
  onWordClick: (
    e: React.MouseEvent | React.KeyboardEvent,
    word: string,
  ) => void;
  isMobile?: boolean;
  questionNum: number;
};

export default function QuestionCard({
  englishWord,
  options,
  selectedWord,
  correctTamilWord,
  onWordClick,
  isMobile,
  questionNum,
}: QuestionCardProps) {
  return (
    <div
      className={
        isMobile
          ? "flex flex-col"
          : "grid gap-2 items-center mr-2 relative left-1.5 h-full py-3.5 z-50"
      }
    >
      <div className="mb-0 text-center">
        <span
          id="staticEnglish_web"
          className="font-primary font-bold text-gray-100 text-2sm leading-6"
        >
          {capitalizeFirstCharOnly(englishWord)} =
        </span>
        <span className="font-primary text-3xs lg:text-2sm font-bold lg:font-normal float-right text-gray-100 leading-4 lg:leading-6">
          {questionNum}/3
        </span>
      </div>
      <div className="m-0">
        <ul
          className={`grid gap-2 p-0 list-none ${isMobile ? "flex-col mt-1 sm:flex-row ml-0" : "flex-row min-w-[420px] lg:min-w-[350px]"}`}
        >
          {options.map((word, index) => (
            <div
              key={index}
              onClick={(e) => {
                onWordClick(e, word);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onWordClick(e, word);
                }
              }}
              role="button"
              tabIndex={0}
              className={twMerge(
                "font-primary font-bold flex justify-center px-1.5 py-2 h-xl bg-white-100 cursor-pointer not-italic leading-normal text-nowrap rounded-300 lg:min-w-[350px]",
                selectedWord !== null
                  ? word === selectedWord
                    ? word === correctTamilWord
                      ? "bg-green-400 text-white-100" // Correct answer
                      : "bg-red-800 text-white-100" // Wrong answer
                    : "border-1 bg-white-100"
                  : "",
              )}
            >
              {capitalizeFirstCharOnly(word)}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}
