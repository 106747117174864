/* eslint-disable react-hooks/rules-of-hooks */
import ArticleCard from "@components/Card/ArticleCard";
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import { Imu1CompanionAdsInfiniteScroll } from "@elements/Advertisement/variant/AdsCompanion";
import fetchTMApi from "@helper/fetchTMApi";
import { displayAfter, hideAfter } from "@helper/utils";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  excludedSections,
  RecentArticlesMetaData,
} from "@pages/RecentArticles/RecentArticlesConstants";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import { Fragment, useState } from "react";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

export default function RecentArticles() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ProcessedArticleData[], string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const recentArticles = dataLoaderResponse.payload;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);

  const showLoadMore: boolean = recentArticles.length == INITIAL_ARTICLE_COUNT;
  useSetFirstRenderedArticleIds(recentArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? recentArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      sort: lastLoadedArticle && lastLoadedArticle.sort,
    };

    const fetchMoreStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      try {
        const queryParams = new URLSearchParams({
          sections: excludedSections.join(","),
          sort: Array.isArray(requestData.sort)
            ? requestData.sort.join(",")
            : "",
          size: "5",
        }).toString();
        const response: ProcessedArticleData[] = await fetchTMApi(
          `more-excluded-sections-stories?${queryParams}`,
          "GET",
        );

        return response;
      } catch (error) {
        return [];
      }
    };

    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const sectionName = "recent-articles";

  const { gaData, metaTags } = RecentArticlesMetaData({
    sectionName,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock sectionName={sectionName}>
          {[...recentArticles, ...loadMoreData].map((section, index) => (
            <Fragment key={section.id}>
              <ArticleCard
                displayWidth={260}
                displayHeight={192}
                elements={{ hasDate: true }}
                {...section}
                overrideStyle={{
                  cardWrapper:
                    (displayAfter(index + 1, PAGE_SIZE)
                      ? "border-none"
                      : "flex-col lg:flex-row") +
                    (hideAfter(index, PAGE_SIZE)
                      ? " flex-col lg:flex-row flex-row-reverse"
                      : ""),
                  titleStyle: hideAfter(index, PAGE_SIZE)
                    ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 lg:text-2lg lg:text-red-100 lg:hover:text-red-200"
                    : "text-md lg:text-2lg",
                  paragraphStyle: hideAfter(index, PAGE_SIZE)
                    ? "hidden lg:block"
                    : "",
                  imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                    ? "w-[90px] h-[90px] lg:h-auto"
                    : "",
                  imageStyle: hideAfter(index, PAGE_SIZE)
                    ? "h-[90px] lg:h-auto"
                    : "",
                }}
                refMedium={"recent_articles"}
              />
              {displayAfter(index + 1, PAGE_SIZE) && (
                <Imu1CompanionAdsInfiniteScroll
                  sectionName={sectionName}
                  index={(index + 1) / PAGE_SIZE}
                  pageAdTargetType={pageAdTargetValue}
                />
              )}
            </Fragment>
          ))}
          {showLoadMore ? (
            <div className="flex w-full justify-center mb-xl">
              <LoadMore
                rootClassName="my-4 w-full"
                onLoadMore={handleLoadMore}
                hasMore={hasMoreStories}
                loadText="Load More"
                mobileInfiniteScroll={true}
              />
            </div>
          ) : null}
        </MainBlock>
        <SideBlock
          sectionName={sectionName}
          className="w-full lg:block"
          pageAdTargetValue={pageAdTargetValue}
          hideAdForMobile={true}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
