/* eslint-disable react-hooks/rules-of-hooks */
import ArticleCard from "@components/Card/ArticleCard";
import { SpecialHeader } from "@components/Header/SpecialHeader";
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import NdSideBlock from "@container/SideBlock/NdSideBlock";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import { Imu1CompanionAdsInfiniteScroll } from "@elements/Advertisement/variant/AdsCompanion";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import Divider from "@elements/Divider/Divider";
import Liveblog from "@elements/Iframe/Liveblog";
import Heading from "@elements/Typography/Heading";
import fetchTMApi from "@helper/fetchTMApi";
import { LIVEBLOG_ID } from "@helper/getEnvVariables";
import { displayAfter, hideAfter } from "@helper/utils";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import ErrorPage from "@pages/ErrorPage";
import { KeywordMetaData } from "@pages/Keyword/KeywordMetaData";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import { KeywordPayloadResponseType } from "@typings/PagePayloadType";
import { Fragment, useState } from "react";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

export default function KeywordPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<KeywordPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const keywordArticles = dataLoaderResponse.payload.data;
  const tagAlias = dataLoaderResponse.payload.tagAlias;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);

  const showLoadMore: boolean = keywordArticles.length == INITIAL_ARTICLE_COUNT;
  useSetFirstRenderedArticleIds(keywordArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? keywordArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: tagAlias,
      sort: lastLoadedArticle?.sort,
    };

    const fetchMoreTagsStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const queryParams = new URLSearchParams({
        tagAlias: requestData.filterArticlesBasedOn as string,
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
        size: "5",
      }).toString();
      const response: ProcessedArticleData[] = await fetchTMApi(
        `more-tags-stories?${queryParams}`,
        "GET",
      );

      return response;
    };
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreTagsStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };
  let tagName = "";
  for (const tag of keywordArticles[0].tags || []) {
    if (tag.alias?.toLowerCase().includes(tagAlias.toLowerCase())) {
      if (tag.name !== undefined) {
        tagName = tag.name;
        break;
      }
    }
  }
  let showBreadcrumbs = true;
  let showTitle = true;
  if (tagName === "தேசிய தினம் 2024" || tagName === "பட்ஜெட் 2025") {
    showBreadcrumbs = false;
    showTitle = false;
  }
  const pageAdTargetValue = PageAdTargetingTypeEnum.TAG;
  const sectionName = "keywords";

  const { gaData, metaTags } = KeywordMetaData({
    tagName,
    tagAlias,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      {(tagName === "பட்ஜெட் 2025" || tagName === "தேசிய தினம் 2024") && (
        <SpecialHeader title={tagName} />
      )}

      {tagName === "பட்ஜெட் 2025" ? (
        <WrapperBlock className="flex flex-col lg:flex-row justify-between">
          <MainBlock
            sectionName={tagName}
            showBreadcrumbs={showBreadcrumbs}
            showTitle={showTitle}
            className="lg:!pr-0 lg:border-0"
          >
            <Liveblog liveblog_id={LIVEBLOG_ID} title="நேரடித் தகவல்" />
          </MainBlock>
          <div className="lg:w-4/12 lg:pr-xxs lg:pl-md min-h-[800px]">
            <div data-testid="side-block" className="w-full">
              <AdsImu2
                sectionName={sectionName}
                pageAdTargetType={pageAdTargetValue}
              />
              <Divider className="border-b border-gray-2200" />

              <Heading
                Element="h2"
                className="text-gray-100 text-md font-bold mt-5"
              >
                அண்மைய செய்திகள்
              </Heading>
              {[...keywordArticles, ...loadMoreData].map((section, index) => (
                <Fragment key={section.id}>
                  <ArticleCard
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key={section.id}
                    displayWidth={175}
                    displayHeight={90}
                    elements={{ hasParagraph: false, hasDate: true }}
                    {...section}
                    overrideStyle={{
                      cardWrapper: "w-full lg:w-full flex-row-reverse pb-5",
                      titleStyle:
                        "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
                      imageWrapperStyle:
                        "w-40 max-w-44 lg:w-40 h-23 max-sm:w-23",
                      contentWrapper: "gap-xs",
                      titleWrapper: "gap-0",
                      imageStyle: "w-full h-23",
                    }}
                  />
                  {displayAfter(index + 1, PAGE_SIZE) && (
                    <Imu1CompanionAdsInfiniteScroll
                      sectionName={sectionName}
                      index={(index + 1) / PAGE_SIZE}
                      pageAdTargetType={pageAdTargetValue}
                    />
                  )}
                </Fragment>
              ))}
              {showLoadMore ? (
                <div className="flex w-full justify-center mb-xl">
                  <LoadMore
                    rootClassName="my-4 w-full"
                    onLoadMore={handleLoadMore}
                    hasMore={hasMoreStories}
                    loadText="Load More"
                    mobileInfiniteScroll={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </WrapperBlock>
      ) : (
        <WrapperBlock className="flex flex-col lg:flex-row justify-between">
          <MainBlock
            sectionName={tagName}
            showBreadcrumbs={showBreadcrumbs}
            showTitle={showTitle}
          >
            {[...keywordArticles, ...loadMoreData].map((section, index) => (
              <Fragment key={section.id}>
                <ArticleCard
                  displayWidth={260}
                  displayHeight={192}
                  elements={{ hasDate: true }}
                  {...section}
                  overrideStyle={{
                    cardWrapper:
                      (displayAfter(index + 1, PAGE_SIZE)
                        ? "border-none"
                        : "flex-col lg:flex-row") +
                      (hideAfter(index, PAGE_SIZE)
                        ? " lg:flex-row flex-row-reverse"
                        : ""),
                    titleStyle: hideAfter(index, PAGE_SIZE)
                      ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 lg:text-2lg lg:text-red-100 lg:hover:text-red-200"
                      : "text-md lg:text-2lg",
                    paragraphStyle: hideAfter(index, PAGE_SIZE)
                      ? "hidden lg:block"
                      : "",
                    imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                      ? "w-[90px] h-[90px] lg:h-auto"
                      : "",
                    imageStyle: hideAfter(index, PAGE_SIZE)
                      ? "h-[90px] lg:h-auto"
                      : "",
                  }}
                />
                {displayAfter(index + 1, PAGE_SIZE) && (
                  <Imu1CompanionAdsInfiniteScroll
                    sectionName={sectionName}
                    index={(index + 1) / PAGE_SIZE}
                    pageAdTargetType={pageAdTargetValue}
                  />
                )}
              </Fragment>
            ))}
            {showLoadMore ? (
              <div className="flex w-full justify-center mb-xl">
                <LoadMore
                  rootClassName="my-4 w-full"
                  onLoadMore={handleLoadMore}
                  hasMore={hasMoreStories}
                  loadText="Load More"
                  mobileInfiniteScroll={true}
                />
              </div>
            ) : null}
          </MainBlock>
          {tagName === "தேசிய தினம் 2024" ? (
            <NdSideBlock
              sectionName={tagName}
              className="w-full lg:block"
              pageAdTargetValue={pageAdTargetValue}
              hideAdForMobile={true}
            />
          ) : (
            <SideBlock
              sectionName={tagName}
              className="w-full lg:block"
              pageAdTargetValue={pageAdTargetValue}
              hideAdForMobile={true}
            />
          )}
        </WrapperBlock>
      )}
    </MainContainer>
  );
}
