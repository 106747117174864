export function capitalize(str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function capitalizeFirstCharOnly(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const truncateText = (text: string, charCount: number) => {
  if (text.length <= charCount) {
    return text;
  }

  const truncatedText = text.substring(0, charCount);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  return lastSpaceIndex === -1
    ? truncatedText
    : truncatedText.substring(0, lastSpaceIndex);
};

export function removeNewlinesAndTabs(str: string) {
  str = str.replace(/\u00a0/g, " ");
  return str.replace(/[\n\t]/g, "");
}

export function tamilSlice(
  str: string,
  start: number,
  length?: number,
): string {
  const diacritics = [
    "\u0B82",
    "\u0BBE",
    "\u0BBF",
    "\u0BC0",
    "\u0BC1",
    "\u0BC2",
    "\u0BC6",
    "\u0BC7",
    "\u0BC8",
    "\u0BCA",
    "\u0BCB",
    "\u0BCC",
    "\u0BCD",
    "\u0BD7",
  ];
  const char = str.split("");
  const tamil = [];
  for (let i = 0; i != char.length; ++i) {
    const ch = char[i];
    if (diacritics.includes(ch)) {
      tamil[tamil.length - 1] += ch;
    } else {
      tamil.push(ch);
    }
  }
  return tamil.slice(start, length).join("");
}
