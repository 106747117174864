import CollapsIcon from "@assets/wordpuzzle/collapse-icon.svg";
import DesktopSmallImg from "@assets/wordpuzzle/Desktop_Small-img_01.svg";
import { twMerge } from "tailwind-merge";

type ResultsProps = {
  isMobile: boolean;
  isExpanded: boolean;
  toggleExpand: () => void;
};

export default function CollapseSection({
  isMobile,
  isExpanded,
  toggleExpand,
}: ResultsProps) {
  return (
    <>
      {!isMobile ? (
        <button
          onClick={toggleExpand}
          className={twMerge(!isExpanded && "absolute", isExpanded && "w-full")}
        >
          <div
            className={twMerge(
              "bg-gray-500 flex justify-center h-[78px] ease duration-700 transition-all",
              !isExpanded && "opacity-0 h-0 invisible",
              isExpanded && "z-50 visible",
            )}
            style={{
              boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)",
            }}
          >
            <div className="flex justify-center items-center w-[975px] h-[78px] py-0 px-sm gap-xxs relative">
              <span className="block relative w-[95px]">
                <img
                  src={DesktopSmallImg}
                  className="absolute top-[-50px] left-[-4px] w-[95px] h-[90px]"
                  alt="Woman Icon"
                />
              </span>
              <div className="block">
                <div className="font-primary text-pink-900 text-xl font-semibold leading-7">
                  முரசு சொல்லாட்டம்
                </div>
                <div className="text-gray-150 text-2sm font-primary leading-6 font-normal pt-2">
                  உங்கள் தமிழ் மொழித்திறனைச் சோதிக்கவும்
                </div>
              </div>
              <span className="absolute right-0 w-[36px] h-[36px]">
                <img
                  src={CollapsIcon}
                  className={twMerge(
                    "cursor-pointer duration-500",
                    isExpanded && "-rotate-180",
                  )}
                  alt="Expand Icon"
                />
              </span>
            </div>
          </div>
        </button>
      ) : (
        <button
          onClick={toggleExpand}
          className={twMerge(!isExpanded && "absolute", isExpanded && "w-full")}
        >
          <div
            className={twMerge(
              "bg-gray-500 flex justify-center h-[50px] ease duration-700 transition-all",
              !isExpanded && "opacity-0 h-0 invisible",
              isExpanded && "z-50 visible",
            )}
            style={{
              boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.2)",
            }}
          >
            <div className="flex items-center h-auto py-0 px-sm gap-xxs">
              <div className="block relative w-[70px]">
                <img
                  src={DesktopSmallImg}
                  className="absolute -bottom-6 left-[-4px] w-[70px] h-[66px]"
                  alt="Woman Icon"
                />
              </div>
              <div className="h-[53px] items-center gap-4">
                <span className="font-primary font-semibold text-md leading-7 text-pink-900">
                  முரசு சொல்லாட்டம்
                </span>
                <div className="text-gray-700 text-3xs font-primary font-normal leading-4 pt-1">
                  உங்கள் தமிழ் மொழித்திறனைச் சோதிக்கவும்
                </div>
              </div>
              <span className="relative h-6 w-6 flex items-center">
                <img
                  src={CollapsIcon}
                  className={twMerge(
                    "cursor-pointer duration-500",
                    isExpanded && "-rotate-180",
                  )}
                  alt="Expand Icon"
                />
              </span>
            </div>
          </div>
        </button>
      )}
    </>
  );
}
