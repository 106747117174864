import { twMerge } from "tailwind-merge";

type QuizButtonProps = {
  handleClick: () => void;
  wrapperStyle?: string;
  buttonStyle?: string;
};

export default function QuizButton({
  handleClick,
  wrapperStyle = "",
  buttonStyle = "",
}: QuizButtonProps) {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        wrapperStyle,
      )}
    >
      <button
        className={twMerge(
          "flex items-center justify-center font-primary bg-600 text-white-100 text-2sm font-bold text-center w-[224px] h-[40px] rounded-3xl py-2.5 px-5 leading-6",
          buttonStyle,
        )}
        onClick={handleClick}
      >
        புதிய புதிரை விளையாட
      </button>
    </div>
  );
}
