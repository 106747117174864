import OutbrainMainContainer, {
  AdsSettings,
} from "@container/OutbrainMainContainer";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import RenderArticleBlocks from "@elements/RenderArticleBlocks/RenderArticleBlocks";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import ArticleContentCard from "@pages/Article/ArticleContentCard";
import ArticleInfiniteScroll from "@pages/Article/ArticleInfiniteScroll";
import ArticleMetaData from "@pages/Article/ArticleMetaData";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import WordPuzzle from "@src/app/components/WordPuzzle/WordPuzzle";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import {
  ArticleAdPageTargetingType,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import { ArticlePayloadResponseType } from "@typings/PagePayloadType";
import { ReactElement, useState } from "react";

export default function ArticlePage(): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ArticlePayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();
  const adsSettings: AdsSettings = useGlobalAdSetting();
  const { gsChannels, isLoading } = useGrapeshotData(
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? (dataLoaderResponse.payload.article.urlPath as string)
      : "",
  );
  const { isScreenLG } = useWindowSize();

  if (
    dataLoaderResponse.type === ResponseType.CLIENT_ERROR ||
    dataLoaderResponse.type === ResponseType.SERVER_ERROR
  ) {
    return dataLoaderResponse.type === ResponseType.CLIENT_ERROR ? (
      <NotFound />
    ) : (
      <ErrorPage message={dataLoaderResponse.payload ?? ""} />
    );
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;

  const mainArticle = dataLoaderResponse.payload.article;
  const sectionName = mainArticle.sectionName;
  const relatedStories = dataLoaderResponse.payload.relatedStories;
  const epaperCoverImageUrl = dataLoaderResponse.payload.epaperCoverImage;
  // Check Cue preview
  const isCuePreview = dataLoaderResponse.payload.isPreview
    ? dataLoaderResponse.payload.isPreview
    : false;

  const articlePageTarget: ArticleAdPageTargetingType[] = [
    { key: "page_number", value: "1" },
    { key: "tmarticleid", value: mainArticle.id },
    {
      key: "tmtags",
      value: mainArticle.tags?.map((tags) => tags.name).join(", ") || "",
    },
  ];

  const handleNewArticle = (newArticles: ProcessedArticleData) => {
    if (newArticles.id !== currentArticle?.id) {
      setCurrentArticle(newArticles);
    }
  };

  const { gaData, metaTags } = ArticleMetaData({
    article: currentArticle || mainArticle,
    isMainArticle: currentArticle ? false : true,
    gsChannels:
      currentArticle && currentArticle.gsChannels
        ? currentArticle.gsChannels
        : gsChannels,
  });

  if (isLoading) {
    // will create a new wrapper container to load the metatags.
    // return <div>...</div>;
  }

  return (
    <OutbrainMainContainer
      metaTagsProps={metaTags}
      gaData={gaData}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
      suppliedAdsSettings={adsSettings}
      gsChannels={gsChannels}
    >
      <div id={mainArticle.id} className="article-card">
        <WrapperBlock className="hover:text flex flex-col lg:flex-row justify-between">
          <ArticleContentCard
            articleData={mainArticle}
            relatedStories={relatedStories}
            pageNumber={0}
            isInfiniteScrollArticle={false}
            hasOutbrain={adsSettings.isOutbrainEnabled}
            gsChannels={gsChannels}
            isCuePreview={isCuePreview}
          />
          {isScreenLG ? (
            <SideBlock
              sectionName={sectionName}
              pageAdTargetValue={pageAdTargetValue}
              articlePageTarget={articlePageTarget}
              gsChannels={gsChannels}
              promoteEpaper={true}
              epaperCoverImageUrl={epaperCoverImageUrl.coverImageUri}
            />
          ) : (
            <>
              {/* Mobile infinite scroll view */}
              {/* Render first block on default article */}
              <RenderArticleBlocks
                pageNumber={0}
                pageUrl={mainArticle.urlPath ? mainArticle.urlPath : ""}
                hasOutbrain={adsSettings.isOutbrainEnabled}
                articleData={mainArticle}
                pageAdTargetValue={pageAdTargetValue}
                gsChannels={gsChannels}
              />
              {/* Render Outbrain block on first article */}
              {adsSettings.isOutbrainEnabled && (
                <div className="pt-5 border-t border-pink-300">
                  <Outbrain pathName="/" version="SB_1" />
                </div>
              )}
              <div className="border-y border-solid border-pink-300 mt-5">
                <AdsImu2
                  sectionName={sectionName}
                  pageAdTargetType={pageAdTargetValue}
                  rootClassName="mt-5"
                  articlePageTarget={articlePageTarget}
                  gsChannels={gsChannels}
                />
              </div>
            </>
          )}
        </WrapperBlock>
      </div>

      <ArticleInfiniteScroll
        mainArticle={mainArticle}
        pageAdTargetValue={pageAdTargetValue}
        onNewArticle={handleNewArticle}
        hasOutbrain={adsSettings.isOutbrainEnabled}
        gsChannels={gsChannels}
        isCuePreview={isCuePreview}
      />
      <WordPuzzle isCatfishEnabled={adsSettings.isCatfishEnabled} />
    </OutbrainMainContainer>
  );
}
