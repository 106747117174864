import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function QuizMetaData(sectionName: string): PageMetaDataValues {
  const level2 = sectionName.replace("-", " ");

  const metaTags: MetaTagsProps = {
    title: "முரசு சொல்லாட்டம் | பொது அறிவு & பொழுதுபோக்கு க்விஸ் - தமிழ்முரசு",
    description:
      "தமிழ்முரசு சொல்லாட்டம் - பொது அறிவு, வரலாறு, சினிமா, விளையாட்டு, மற்றும் பல தொழில்நுட்பக் கேள்விகளுடன் உங்கள் அறிவை சோதிக்கவும். உடனே விளையாடுங்கள்!",
    slug: sectionName,
    keywords: [
      "முரசு சொல்லாட்டம்",
      "தினசரி வினாடி வினா",
      "Word Quiz",
      "Daily Quiz",
      "Tamil Quiz",
      "Tamil Word Quiz",
      "தமிழ் வினாடி வினா",
      "Tamil trivia",
      "தமிழ் பொழுதுபோக்கு",
      "தமிழ் கல்வி",
    ],
  };

  const gaData: GADataProps = {
    level2: level2,
    title: `${sectionName.replace("-", "_")}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
