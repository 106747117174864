import DesktopMainImg from "@assets/wordpuzzle/Desktop_Main-img_01.svg";

import QuestionCard from "./QuestionCard";
import ResultsSection from "./ResultsSection";
import StatusMessage from "./StatusMessage";

export type Questions = { [key: string]: string }[];

type ResultsProps = {
  isMobile: boolean;
  questionNum: number;
  questions: Questions;
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctTamilWord: string;
  handleWordClick: (word: string) => void;
  resultVisible: boolean;
  handleQuizNavigation: () => void;
  status: string;
  incorrectCombination: string;
};

function QuestionDetailSection({
  isMobile,
  questionNum,
  questions,
  englishWord,
  options,
  selectedWord,
  correctTamilWord,
  handleWordClick,
  resultVisible,
  handleQuizNavigation,
  status,
  incorrectCombination,
}: ResultsProps): JSX.Element {
  const progressPercentage = (questionNum / 10) * 100;
  return (
    <>
      {!isMobile ? (
        <div className="flex justify-center gap-10 bg-gray-500 items-center pb-6">
          <div className="w-[480px] pt-6">
            <div className="flex items-center relative h-[182px] px-5">
              <img
                src={DesktopMainImg}
                width={192}
                height={172}
                alt="Woman Icon"
                className="block"
              />
              <span className="font-primary relative -left-1">
                <div className="text-lg font-semibold leading-9 text-pink-900">
                  முரசு சொல்லாட்டம்
                </div>
                <div className="text-gray-150 text-2sm font-bold leading-7">
                  உங்கள் தமிழ் மொழித்திறனைச் சோதிக்கவும்
                </div>
              </span>
            </div>
            {/* Progress Bar */}
            <div className="flex w-full gap-xs items-center font-primary relative -top-2">
              <div className="w-full bg-gray-2050 rounded-[8px] h-[8px]">
                <div
                  className="bg-orange-500 h-[8px] rounded-[8px]"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <div className="text-2sm text-pink-500 leading-6 font-bold">
                {questionNum}/10
              </div>
            </div>
          </div>

          {questions.length > 0 && !resultVisible && (
            <QuestionCard
              englishWord={englishWord}
              options={options}
              selectedWord={selectedWord}
              correctTamilWord={correctTamilWord}
              onWordClick={handleWordClick}
            />
          )}

          {resultVisible && (
            <ResultsSection handleQuizNavigation={handleQuizNavigation} />
          )}
        </div>
      ) : (
        <div className="flex w-full flex-col justify-center">
          <div className="bg-gray-500 px-sm py-4 w-full">
            <div className="flex items-center justify-center">
              <img
                src={DesktopMainImg}
                alt="logo"
                width={157}
                height={166}
                className="px-2 h-auto w-[157px]"
              />
              <span className="font-primary relative">
                <div className="text-3md font-semibold leading-8 text-pink-900">
                  முரசு சொல்லாட்டம்
                </div>
                <div className="text-gray-150 text-sm font-semibold leading-250">
                  உங்கள் தமிழ் மொழித்திறனைச் சோதிக்கவும்
                </div>
              </span>
            </div>
            {/* Progress Bar */}
            <div className="flex w-full gap-xs items-center font-primary relative -top-2">
              <div className="w-full bg-gray-2050 rounded-[8px] h-[8px]">
                <div
                  className="bg-orange-500 h-[8px] rounded-[8px]"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <div className="text-2sm text-pink-500 leading-6 font-bold">
                {questionNum}/10
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <StatusMessage
              status={status}
              incorrectCombination={incorrectCombination}
              resultsVisible={resultVisible}
            />
            {questions.length > 0 && !resultVisible && (
              <QuestionCard
                englishWord={englishWord}
                options={options}
                selectedWord={selectedWord}
                correctTamilWord={correctTamilWord}
                onWordClick={handleWordClick}
                isMobile={isMobile}
              />
            )}

            {resultVisible && (
              <ResultsSection handleQuizNavigation={handleQuizNavigation} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default QuestionDetailSection;
