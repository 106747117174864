import StarImg from "@assets/wordpuzzle/star-quiz.svg";

import QuizButton from "./QuizButton";

type ResultsSectionProps = {
  handleQuizNavigation: () => void;
};

export default function ResultsSection({
  handleQuizNavigation,
}: ResultsSectionProps) {
  return (
    <div className="flex w-[400px]">
      <div className="flex w-full items-center justify-center gap-2 flex-col">
        <div className="relative top-5">
          <img src={StarImg} alt="star-kuiz" width={260} height={90} />
        </div>
        <div className="flex flex-col gap-2 font-semibold items-center text-grey-800">
          <span className="text-[30px] lg:text-xxxl">நன்று!</span>
          <span className="text-md lg:text-[20px]">சிறப்பான முயற்சி</span>
        </div>
        <QuizButton handleClick={handleQuizNavigation} />
      </div>
    </div>
  );
}
