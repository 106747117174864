import { URL_ORIGIN } from "@elements/MetaTags/MetaTagsConstants";
import { removeLeadingSlash } from "@helper/removeTrailingLeadingSlash";
import { formatDate } from "@helper/useDateFormat";
import { ProcessedAuthorContent } from "@src/app/types/Opensearch";
import { ProcessedArticleData } from "@transformer/useOsResponse";

export function getArticleJSONLD(
  article: ProcessedArticleData,
  authorData: ProcessedAuthorContent[],
): object {
  const { urlPath, title, imageField, datetime, dateEdited } = article;
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "NewsArticle",
        headline: title,
        name: title,
        image: {
          "@type": "ImageObject",
          representativeOfPage: "True",
          url: imageField?.url,
        },
        datePublished: formatDate(datetime, "MMM d, yyyy | hh:mm a"),
        dateModified: formatDate(dateEdited, "MMM d, yyyy | hh:mm a"),
        // hasPart: {
        //   "@type": "WebPageElement",
        //   cssSelector: ".paywall",
        // },
        isAccessibleForFree: "True",
        author: authorData.map((author) => ({
          "@type": "Person",
          name: author.name,
          jobTitle: author.designation,
          url: `${URL_ORIGIN}${removeLeadingSlash(author.urlPath || "")}`,
        })),
        publisher: {
          "@type": "Organization",
          name: "தமிழ் முரசு",
          url: URL_ORIGIN,
        },
        mainEntityOfPage: `${URL_ORIGIN}${removeLeadingSlash(urlPath || "")}`,
      },
    ],
  };

  return jsonLd;
}
