import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import getPageDetails from "@helper/getPageDetails";
import { getArticleJSONLD } from "@helper/jsonLDHelper";
import { capitalize } from "@helper/stringTransform";
import { dateFormatWithTz, formatDate } from "@helper/useDateFormat";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import {
  ProcessedArticleBody,
  ProcessedAuthorContent,
  ProcessedFullParagraph,
  ProcessedTagsContent,
} from "@typings/Opensearch";
import type { PageMetaDataValues } from "@typings/PageMetaData";

type ArticleMetaDataProps = {
  article: ProcessedArticleData;
  isMainArticle: boolean;
  gsChannels?: string[];
};

export default function ArticleMetaData({
  article,
  isMainArticle,
  gsChannels,
}: ArticleMetaDataProps): PageMetaDataValues {
  const keywords = getTagsName(article.tags || []);

  const { label } = getPageDetails(article.sectionName) || {
    label: article.sectionName,
  };

  const capitalizeSectionName = article.sectionName
    ? capitalize(article.sectionName)
    : article.sectionName;

  const authors = article.authors;
  const authorData = Array.isArray(authors) ? authors : [];
  // const metaAuthors =
  //   Array.isArray(authors) && authors.length > 0
  //     ? getAuthorNames(authors)
  //     : "";

  const gaData: GADataProps = {
    level2: article.sectionName || "",
    title: article.title,
    contentcat: 1,
    articleid: article.id,
    cue_articleid: article.id,
    contenttype: "1",
    pagination: 1,
    author: getAuthorsName(article.authors || []),
    infinite_scroll: isMainArticle ? "0" : "1",
    pubdate: formatDate(article.datetime, "yyyy-MM-dd HH:mm:ss"),
    content_publication_date_utc: article.datetime,
    content_update_date_utc: article.dateEdited,
    keyword: keywords,
  };
  let pageUrl = `${article.urlPath}`;
  pageUrl = pageUrl.replace(/^\/+/, "");

  const metaTags: MetaTagsProps = {
    isArticlePage: true,
    title: `${article.title}, ${label} செய்திகள் - தமிழ் முரசு ${capitalizeSectionName} News in Tamil, Tamil Murasu`,
    description: getFirstParagraph(article.articleBody),
    keywords: keywords.split(","),
    ogType: "Article",
    slug: pageUrl,
    image: article.imageField?.url,
    publishedTime: formatDate(article.datetime, dateFormatWithTz),
    modifiedTime: formatDate(article.dateEdited, dateFormatWithTz),
    jsonLd: getArticleJSONLD(article, authorData),
    gsChannels,
  };

  return {
    gaData,
    metaTags,
  };
}

function getFirstParagraph(articleBody: ProcessedArticleBody | null): string {
  if (!articleBody) return "";
  const para = articleBody.find(
    (element) => element.type === "paragraph",
  ) as ProcessedFullParagraph;
  return para.value;
}

export function getTagsName(tags: ProcessedTagsContent[]): string {
  return tags.map((tag) => tag.name).join(",");
}

export function getAuthorsName(authors: ProcessedAuthorContent[]): string {
  return authors.map((author) => author.name).join("|");
}

export const getAuthorNames = (authors: ProcessedAuthorContent[]) => {
  return authors.map((a) => a.name.trim()).join(", ");
};
