import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { HeaderMenuDetails } from "@header/HeaderMenuConstants";
import { HeaderMenuType } from "@header/HeaderMenuTypes";
import { SubMenuDropdown } from "@header/HeaderSubMenuDropdown";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

type HeaderMenuListProps = {
  testId: string;
  items: HeaderMenuDetails[];
  type: HeaderMenuType;
  isHamburger: boolean;
};

export function HeaderMenuList({
  testId,
  items,
  type,
  isHamburger,
}: HeaderMenuListProps) {
  const [dropdown, setDropdown] = useState(isHamburger);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    setDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <List
      testId={`${testId}-${type}-list`}
      className={cx(
        "block",
        type === "social" && "inline-flex xs:pt-sm lg:pt-0",
        type === "main" &&
          "lg:inline-flex lg:w-full lg:justify-center lg:mb-6 lg:relative",
      )}
    >
      {items.map((item: HeaderMenuDetails, index: number) => (
        <ListItem
          key={`${testId}-item-${index}`}
          testId={`${testId}-item-${index}`}
          className={cx(
            "font-sans text-gray-200",
            type === "social" && "px-4 mx-0.5 lg:px-2 lg:mx-0 lg:py-1",
            type === "main" && item.url && "pt-sm lg:pt-0 lg:flex",
            type === "top-mobile" && "pt-sm first:pt-md even:pt-xs lg:pt-0",
          )}
        >
          {item.child && item.childrens ? (
            <>
              <div ref={dropdownRef}>
                <button
                  type="button"
                  aria-haspopup="menu"
                  aria-expanded={dropdown ? "true" : "false"}
                  className="font-bold text-md lg:text-sm lg:px-3 hidden lg:block lg:py-2 hover:text-pink-200"
                  onClick={toggleDropdown}
                >
                  {dropdown ? (
                    <span className="after:ml-1 ml-1 hover:after:border-y-pink-200 after:inline-block after:content-[''] after:border-solid after:border-x-[.3em] after:border-x-transparent after:border-b-[.3em] after:border-t-0 after:align-[.15em]">
                      {item.title}
                    </span>
                  ) : (
                    <span className="after:ml-1 ml-1 hover:after:border-y-pink-200 after:inline-block after:content-[''] after:border-solid after:border-x-[.3em] after:border-x-transparent after:border-t-[.3em] after:border-b-0 after:align-[.15em]">
                      {item.title}
                    </span>
                  )}
                </button>

                <SubMenuDropdown
                  submenus={item.childrens}
                  dropdown={dropdown}
                  testId={testId}
                  type={type}
                />
              </div>
            </>
          ) : (
            <>
              <Link
                reloadDocument
                key={index}
                to={item.url}
                target={item.newTab ? "_blank" : "_self"}
                rel="noreferrer"
                className={cx(
                  "font-bold text-md tracking-[0.5px]",
                  item.images && item.title && "inline-flex",
                  type === "top-mobile" && "text-red-100",
                  type === "main" &&
                    "hover:text-pink-200 lg:text-sm lg:px-2.5 lg:py-2 xl:px-5 xl:py-2.5",
                )}
              >
                {item.images ? (
                  <img
                    height={item.height}
                    width={item.width}
                    src={item.images}
                    alt={`${item.alt}-icon`}
                    className={cx(
                      "h-5",
                      type === "social" && "xs:h-6 lg:h-5",
                      type === "top-mobile" && "h-xm pr-1 mt-0.5 ml-1",
                      type === "main" && "lg:h-auto lg:w-12.5 lg:mt-1",
                      item.url === "/tabla" &&
                        "xs:h-4 xs:w-auto xs:mt-xs xs:ml-3xs xs:mb-3xs lg:!mt-0.5",
                    )}
                  />
                ) : null}
                {item.title}
              </Link>
            </>
          )}
        </ListItem>
      ))}
    </List>
  );
}
